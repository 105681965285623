import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 适配移动端
import 'lib-flexible/flexible.js'
// 引入qs
import qs from "qs"
Vue.prototype.qs = qs
import 'vant/lib/index.css';
import {Button,List,Field,Popup,DatetimePicker,Overlay,Stepper,Toast,Tab,Tabs,DropdownMenu,DropdownItem} from 'vant'
Vue.use(Button)
Vue.use(List)
Vue.use(Field)
Vue.use(Popup )
Vue.use(DatetimePicker )
Vue.use(Overlay)
Vue.use(Stepper)
Vue.use(Toast)
Vue.use(Tabs)
Vue.use(Tab)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
// 引入全局基础css
import "@/assets/css/reset.css"

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
