import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: "index"
  },
  {
    path:'/index',
    name:'index',
    component: () => import('@/views/index/')
  },
  {
    path:'/login',
    name:'login',
    component: () => import('@/views/login/'),
    meta: {
      title: "登陆"
    }
  },
  {
    path:'/salesDetails',
    name:'salesDetails',
    component: () => import('@/views/salesDetails/'),
    meta: {
      title: "销量明细"
    }
  }
]

const router = new VueRouter({
  routes
})

export default router
